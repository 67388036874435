function scrollActive() {
	let links = document.querySelectorAll('.item-nav__link')
	let sections = document.querySelectorAll('[data-anchor]')
	window.onscroll = () => {
		let coords = window.scrollY
		links.forEach((link, id) => {
			if (sections[id].offsetTop - (document.querySelector('.header').clientHeight + 20) <= coords) {
				links.forEach(el => el.classList.remove('item-nav__link--active'))
				link.classList.add('item-nav__link--active')
				if (id >= 2) {
					document.querySelector('.up').classList.add('up--active')
				} else {
					document.querySelector('.up').classList.remove('up--active')
				}
			}
		})
	}
}
