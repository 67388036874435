function AnimationCounter() {
    try {
        function separateThousands(t) {
            for (
                var t = (t += "").split("."),
                n = t[0],
                t = 1 < t.length ? "." + t[1] : "",
                o = /(\d+)(\d{3})/;
                o.test(n);

            )
                n = n.replace(o, "$1 $2");
            return n + t;
        }
        // $(function () {
        //     $(".up-down-roller__top-layer").resizable({
        //         containment: ".up-down-roller__outer",
        //         maxHeight: 647,
        //         minHeight: 0,
        //     });
        // });

        var breakpoint768 = window.matchMedia("(max-width: 768px)");
        function animateCounter() {
            if (document.querySelector(".pct-counter") != null) {
                $(".pct-counter").each(function () {
                    var t = $(this),
                        n = t.attr("data-count");
                    $({ countNum: t.text() }).animate(
                        { countNum: n },
                        {
                            duration: 2e3,
                            easing: "linear",
                            step: function () {
                                t.text(Math.floor(this.countNum));
                            },
                            complete: function () {
                                (this.countNum = separateThousands(this.countNum)),
                                    t.text(this.countNum);
                                $(".section-scorer__items").addClass("section-scorer__items--complete");
                            },
                        },
                    );
                });
            }
        }
        if (document.querySelector('.pct-counter') != null) {
            $(function () {
                breakpoint768.matches
                    ? $(".pct-counter").each(function (t) {
                        var n = $(this),
                            o = n.data("count");
                        n.text(o);
                    })
                    : $(window).on("scroll", function () {
                        490 < $(window).scrollTop() &&
                            (animateCounter(), $(window).off("scroll"));
                    });
            });
        }
    }
    catch {
    }
}

