function initFormValidation() {

  // function showSuccess() {
  //   alert('отправлено');
  // }

  function submitHandlerForms(form) {
    console.log("Отправка формы!!");

    // form.reset();
    // showSuccess();
  }

  function validateForm(form) {
    $(form).validate({
      errorClass: "input-validation input-validation--invalid",
      validClass: "input-validation input-validation--valid",
      errorElement: "span",
      errorPlacement: ($error, $element) => {
        if ($element.attr("name") === "checkbox") {
          $error.insertAfter($element.parent());
        } else {
          $error.insertAfter($element);
        }

        console.log($element);
        console.log($error);
        $element
          .closest(".input, .checkbox, .radio, .select")
          .find(".input-validation__message")
          .html($error);
      },
      normalizer: (value) => $.trim(value),
      rules: {
        checkbox: { required: true },
        name: {
          required: true,
          minlength: 2,
          normalizer: (value) => value.replace(),
        },
        email: { email: true },
        tel: {
          normalizer: (value) => value.replace(/\D+/g, ""),
          required: true,
          minlength: 11,
        },
        timee: {
          required: true,
          minlength: 5,
          normalizer: (value) => value.replace(),
        },
        message: {
          minlength: 3,
        },
        password1: {
          required: true,
          minlength: 6,
        },
        password2: {
          required: true,
          minlength: 6,
        },
      },
      messages: {
        checkbox: { required: "Укажите согласие c правилами пользования" },
        timee: { minlength: "Укажите полное время" },
        message: { minlength: "Введите не менее 3 символов" },
      },
      submitHandler: submitHandlerForms,
    });
  }

  function initFormValidation(valForm) {
    $(valForm).each((i, form) => {
      validateForm(form);
    });
  }

  initFormValidation(".js-form-validation");
}
