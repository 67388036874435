function initSmoothScrolling() {
  try {
    const anchors = document.querySelectorAll('a[href*="#"]')

    for (let anchor of anchors) {
      anchor.addEventListener('click', function (e) {
        const blockID = anchor.getAttribute('href').substr(1)
        if (blockID.length >= 2) {
          e.preventDefault()
          try {
            document.getElementById(blockID).scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          } catch {
            console.log('Ошибка initSmoothScrolling (scrollIntoView)')
          }
        }
      })
    }
  } catch {
    console.log('Ошибка initSmoothScrolling')
  }
}
