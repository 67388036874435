function anchor() {
	let btn = document.querySelector('.info__nav-list-inner')
	if (!btn) return
	btn.onclick = scroll.bind(btn)
}

function scroll() {
	let header = document.querySelector('header')
	scrollTo({
		top: $(this).offset().top + this.clientHeight - header.clientHeight,
		behavior: 'smooth',
	})
}
