function OpenCloseCard() {
  try {

		function removeDot(elemClass) {
			let mainActiveClass = '';
			let mainArray = elemClass.split("");
			let mainClass = mainArray.map((item, index)=>item == "." ? mainArray.splice(index, 0): mainActiveClass += item);
			return mainActiveClass;
		}

		function toggleShow(mainElem, elements, btn, animated = "slideToggle", addMainActive = false) {
			document.addEventListener("mousedown", (event) => {
				if (event.target.closest(mainElem) && event.target.closest(btn)) {
					let pathMain = event.target.closest(mainElem);
					let elemsToggleShow = [];

					if (addMainActive) {
						pathMain.classList.toggle(`${removeDot(mainElem)}--active`);
					}
					elements.forEach((itemElems) =>  {
						elemsToggleShow.push(pathMain.querySelector(`.${itemElems}`));
					});
					elemsToggleShow.forEach((itemElem) => {
						console.log();
						switch (animated) {
							case "toggle":
								$(itemElem).toggle(300);
								break;
							case "slideDown":
								$(itemElem).slideDown(300);
								break;
							case "slideUp":
								$(itemElem).slideUp(300);
								break;
							case "slideToggle":
								$(itemElem).slideToggle(300);
								break;
								case "fadeToggle":
									$(itemElem).fadeToggle(300);
									break;
						}
					});
				}
			});
		}
		
		toggleShow(".item-dropdown", ["item-dropdown__hidden"], ".item-dropdown__visible","slideToggle", true);


  } catch (e) {
    console.log("Ошибка OpenCloseCard" + e);
  }
}
