function initFancyBox() {

	jQuery('.fancybox').fancybox({
		padding: 0, //убираем отступ
		helpers: {
			overlay: {
				locked: false, // отключаем блокировку overlay
			},
		},
	})

	$.extend(true, $.fancybox.defaults, {
		// ! Анимация открытия
		// false - отключить
		// "zoom" - увеличить изображение от / до миниатюры
		// "fade"
		// "zoom-in-out"
		animationEffect: 'zoom-in-out',

		// ! Анимация перехода между слайдами
		//   false            - disable
		//   "fade'
		//   "slide'
		//   "circular'
		//   "tube'
		//   "zoom-in-out'
		//   "rotate'
		transitionEffect: 'fade',

		// ! Анимация перехода между слайдами
		transitionDuration: 1000,
		// ! Бесконечное прокручивание слайдов
		loop: true,
		// ! Переключение слайдов прокруткой мышью
		wheel: 'auto',
		// ! Защита слайдов (отключает правый щелчок мыши, чтобы не было возможности просмотреть код)
		protect: false,
		// ! Переключение с помощью клавиатуры
		keyboard: true,
		// ! Отключение/ включение стрелок переключения слайдов
		arrows: true,
		// ! Отключение/ включение Инфобара (это где пишется количество слайдов)
		infobar: true,
		// ! Отключение/ включение Тулбара (это где выбор элементов (лупа, скачать картинку и тд))
		toolbar: true,

		// ? Выбор активных кнопок в Тулбаре

		buttons: ['zoom', 'share', 'slideShow', 'fullScreen', 'download', 'thumbs', 'close'],

		// ? Русификатор кнопок (при наведение на них доп информация)

		lang: 'ru',
		i18n: {
			ru: {
				CLOSE: 'Закрыть',
				NEXT: 'Вперед',
				PREV: 'Назад',
				ERROR: 'Запрашиваемый контент не может быть загружен. <br/> Пожалуйста, попробуйте позже.',
				PLAY_START: 'Начать демонстрацию',
				PLAY_STOP: 'Приостановить демонстрацию',
				FULL_SCREEN: 'На полный экран',
				THUMBS: 'Миниатюры',
				DOWNLOAD: 'Скачать',
				SHARE: 'Поделиться',
				ZOOM: 'Увеличить',
			},
		},
	})

	$('[data-fancybox-window]').fancybox({
		closeExisting: false,
		infobar: false,
		touch: false,
		toolbar: false,
		// modal: false,
		// hideOnOverlayClick: true,
		// enableEscapeButton: true,
		// clickOutside: true,
		// closeExisting: false,
	})
}
