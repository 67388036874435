function getProjects() {
    if (!document.querySelector('.gallery__button')) return
    let parent = document.querySelector('.gallery__wrapper')
    let btnWrap = parent.querySelector('.gallery__button')
    let btn = btnWrap.querySelector('a')
    let ul = parent.querySelector('.gallery__list-area')
    getAll(parent, ul)

    btn.onclick = () => {
        let ulCloned = parent.querySelectorAll('ul:not(:first-child)')
        ulCloned.forEach(ulList => {
            ulList.style = 'max-height: 40000px; transition: max-height 10s, opacity 2s; opacity: 1;'
        })
        btnWrap.style = 'opacity: 0; transition: all .5s; max-height: 1px; margin: 0;'
        setTimeout(() => {
            parent.removeChild(btnWrap)
        }, 500)
    }
}

function getAll(parent, list) {
    let clone
    let responseObjects
    fetch(`${parent.getAttribute('data-path')}`)
        .then((res) => res.json())
        .then(data => {
            responseObjects = Object.keys(data).map((key, id) => {
                return {
                    id: id,
                    ...data[key]
                }
            })

            // Определение количества клонированных списков
            let quantityLists = Math.ceil(responseObjects.length / 7)

            // Клонирование списков
            for (let i = 0; i < quantityLists - 1; i++) {
                // Клонирование на основе главного листа страницы
                clone = list.cloneNode(true)

                clone.style = 'max-height: 1px; opacity: 0; pointer-events: none; transition: max-height 1s'
                // Добавление как детей элемента parent
                parent.appendChild(clone)
            }

            // Получение клонированных списков 
            let lists = parent.querySelectorAll('ul')

            lists.forEach((list, i) => {
                let items = list.querySelectorAll('li')
                items.forEach((item, id) => {
                    let obj = responseObjects[id + 7 * i]
                    if (obj) {
                        item.setAttribute(obj.filterType, '')
                        item.outerHTML = `
                            <li class="gallery__item-area" ${obj.filterType}>
                                <a class="gallery__card-c Card-c" href=${obj.link}>
                                    <img class="Card-c__img" src=${obj.path}>
                                    <h3 class="Card-c__title Title Title--color-e">${obj.title}</h3>
                                    <p class="Card-c__text Text Text--color-e Text Text--s">${obj.desc}</p>
                                </a>
                            </li>
                        `
                    } else {
                        list.removeChild(item)
                    }
                })
            })

            select()

            // responseObjects.forEach((obj, index) => {


            //     let items = clone.querySelectorAll('li')


            //     items[index].innerHTML = `
            //         <a class="gallery__item-link" href=${obj.link}>
            //             <img src=${obj.path}>
            //         </a>
            //     `
            // })

            // if (responseObjects.length % 8 !== 0) {
            //     let lists = parent.querySelectorAll('ul:not(:first-child)')
            // }

        })
}