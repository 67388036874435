function formApi(form) {
	if (form.querySelectorAll('.input-validation--valid').length >= 3 && form.querySelector('[type="checkbox"]').checked) {
		$(document).on('click', '[type="submit"]', function (e) {
			// e.preventDefault();
			var $this = $(this)
			var $form = $this.closest('form')
			var formUrl = $form.attr('action')
			let message = document.querySelector('.popup-write-us__status')
			message.setAttribute('style', 'display:none')
			$.ajax({
				url: formUrl,
				// url: 'https://jsonplaceholder.typicode.com/posts',
				method: 'post', // Type of request to be send, called as method
				data: new FormData($form.get(0)), // Data sent to server, a set of key/value pairs (i.e. form fields and values)
				contentType: false, // The content type used when sending data to the server.
				cache: false, // To unable request pages to be cached
				processData: false, // To send DOMDocument or non processed data file it is set to false

				dataType: 'json',
				success: function (res) {
					console.log('ok')
					console.log(res)

					if (!res.hasError) {
						$form.find('input, textarea').val('')
						$('.ctrFiles').html('')
					}

					if (res.status === 'success') {
						message.removeAttribute('style')
						message.classList.add('popup-write-us__status--success')
						message.classList.remove('popup-write-us__status--error')
						// $form.trigger('reset')
						// form.querySelector('[type="checkbox"]').checked = false
						setTimeout(() => {
							location.reload()
						}, 2000)
						message.textContent = 'Заявка успешно отправлена'
						// document.querySelectorAll('.Complains__list-item > a').forEach(el => el.dispatchEvent(new Event('click')))
					} else {
						message.textContent = 'Ошибка отправки'
						message.removeAttribute('style')
						message.classList.remove('popup-write-us__status--success')
						message.classList.add('popup-write-us__status--error')
					}
				},
				error: function (p1, p2, p3) {
					console.log(p1)
					console.log(p2)
					console.log(p3)
					message.textContent = 'Ошибка отправки'
					message.removeAttribute('style')
					message.classList.remove('popup-write-us__status--success')
					message.classList.add('popup-write-us__status--error')
					// document.querySelector('.Complains__status-msg').setAttribute('style', 'color: red; display: block;')
					// document.querySelector('.Complains__status-msg').textContent = 'Ошибка отправки формы'
				},
			})

			return false
		})
		$('[type="submit"]').click()
	}
}
